<template>
<footer class="footer">
    <p>Creator: Mona Singh</p>
</footer>

  
</template>

<script>
export default {

}
</script>


<style lang="scss">
@import "@/styles/components/_footer.scss"
</style>